import React, {useState} from 'react';
import './App.css';
import Header from './components/Header/Header';
import Header_login from './components/Header/Header_login';
import Footer from './components/Footer/Footer';
import LoginForm from './components/LoginForm/LoginForm';
import RegistrationForm from './components/RegistrationForm/RegistrationForm';
import VerificationEmail from './components/VerificationEmailPage/VerificationEmail';

import UpdateProfileForm from './components/UpdateProfileForm/UpdateProfileForm';
import ProfilePage from './components/ProfilePage/ProfilePage';

import Home from './components/Home/Home';
import PrivateRoute from './utils/PrivateRoute';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import AlertComponent from './components/AlertComponent/AlertComponent';  
 function App() {
  const [title, updateTitle] = useState(null);
  const [errorMessage, updateErrorMessage] = useState(null);
  return (
    // <Router basename="/clinch">
    <Router>
    <div className="App">
    
        <div className="container d-flex align-items-center flex-column">
          <Switch>
            <Route path="/" exact={true}>
            <Header title={title}/>
              <LoginForm showError={updateErrorMessage} updateTitle={updateTitle}/>
            </Route>
            <Route path="/login">
            <Header title={title}/>
              <LoginForm showError={updateErrorMessage} updateTitle={updateTitle}/>
            </Route>
            <Route path="/register">
            <Header title={title} />
              <RegistrationForm showError={updateErrorMessage} updateTitle={updateTitle}/>
            </Route>
            
            <Route path="/UpdateProfileForm">
            <Header_login/>
              <UpdateProfileForm showError={updateErrorMessage} updateTitle={updateTitle}/>
            </Route>
            <Route path="/ProfilePage">
            <Header_login/>
              <ProfilePage showError={updateErrorMessage} updateTitle={updateTitle}/>
            </Route>
            <PrivateRoute path="/home">
            <Header_login/>
              <Home/>
            </PrivateRoute>
           
            <Route path="/email-verification">
            <Header/>
              <VerificationEmail/>
              </Route>
          </Switch>
          <AlertComponent errorMessage={errorMessage} hideError={updateErrorMessage}/>
        </div>
        
    </div>
    <Footer/>
    </Router>
  );
}

export default App;
