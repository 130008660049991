import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function VerificationEmail(props) {
    const theme = createTheme();
    return (
        <ThemeProvider theme={theme}>
         <Container className="marginTopDiv" component="main" maxWidth="">
             <div className="innerContainer">
                 <h1>Great! You've successfully signed up for Oil Changer's Rewards</h1>
                 <div className="mailIcon"></div>
                 <p>We've sent you a link to confirm your email address.
                    Please check your inbox. It could take up to 10 minutes to show up in your inbox. </p>

             </div>
         </Container>
        </ThemeProvider>
    )
}

export default withRouter(VerificationEmail);