import React from "react";
import { withRouter } from "react-router-dom";
import { ACCESS_TOKEN_NAME } from "../../constants/apiConstants";
function Header(props) {
  let isLoggedIn = "";
  let loginUserDta = "";
  let loginUser = "";
  if (localStorage.getItem("login_access_token")) {
    isLoggedIn = localStorage.getItem("login_access_token");
    //console.log(JSON.parse(localStorage.getItem("data")).data.first_name);
    if (JSON.parse(localStorage.getItem("data"))) {
      loginUserDta = JSON.parse(localStorage.getItem("data")).data.first_name;
      if (loginUserDta) {
        loginUser = loginUserDta.charAt(0);
      }

      console.log(isLoggedIn);
    }
  }
  window.addEventListener("mouseup", function (event) {
    var box = document.getElementById("navbarSupportedContent");
    if (event.target != box && event.target.parentNode != box) {
      if (box && box.className.search("show") != -1) {
        box.classList.remove("show");
      }
    }
  });

  var message = "";
  var messageSuc = "";
  if (window.location.href.indexOf("?") > -1) {
    //alert(decodeURIComponent(window.location.href).split('?')[1])
    // setTimeout(function(){
    // alert(decodeURIComponent(window.location.href).split('?')[1].replace('error=',''))
    if (window.location.href.indexOf("error=") > -1) {
      message = decodeURIComponent(window.location.href).split("?")[1].replace("error=", "");
    } else {
      messageSuc = decodeURIComponent(window.location.href).split("?")[1].replace("success=", "");
    }

    //   },1000)

    //decodeURIComponent(window.location.href).split('?')[1]);
  }

  setTimeout(function () {
    const succ = document.getElementById("emailConfirmSucc");
    if (succ) {
      succ.style.display = "none";
    }
    const fail = document.getElementById("emailConfirmFail");
    if (fail) {
      fail.style.display = "none";
    }
  }, 3000);

  // const toggle = () => setIsOpen(!isOpen);

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  let title = capitalize(props.location.pathname.substring(1, props.location.pathname.length));
  if (props.location.pathname === "/") {
    title = "Welcome";
  }
  function renderLogout() {
    if (props.location.pathname === "/home") {
      return (
        <div className="ml-auto">
          <button className="btn btn-danger" onClick={() => handleLogout()}>
            Logout
          </button>
        </div>
      );
    }
  }
  function handleLogout() {
    localStorage.clear();
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    props.history.push("/login");
  }
  function redirectToUpdateProfile() {
    // props.updateTitle('Upadte')//
    props.history.push("/ProfilePage", { state: "homeData" });
  }
  return (
    <div className="row container-header">
      <div className="row sevendays">
        <div className="container">
          <div className="row">
            <div className="col-8 fr">
              <a href="https://oilchangers.com/covid-19-update/">We’re open 7 days a week, no appointment necessary!</a>
            </div>
            <div className="col-4 menu">
              <ul className="mr-custom">
                <li className="facebookIcon">
                  <a href="https://facebook.com/oilchangersnearme" target="_blank">
                    <i className="fa fa-facebook-square"></i>
                  </a>
                </li>
                <li className="instaIcon">
                  <a href="https://www.instagram.com/oilchangers/?hl=en" target="_blank">
                    <i className="fa fa-instagram icon-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="https://oilchangers.com/contact/" target="_blank" className="contactTxt">
                    CONTACT
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div
              id="emailConfirmFail"
              className="  alert-danger alert-dismissable mt-2 topMargin"
              style={{ display: message ? "block" : "none", left: 0, right: 0 }}
              role="alert"
            >
              {message}
            </div>
            <div
              id="emailConfirmSucc"
              className="  alert-success alert-dismissable mt-2 topMargin"
              style={{ display: messageSuc ? "block" : "none", left: 0, right: 0 }}
              role="alert"
            >
              {messageSuc}
            </div>
          </div>
        </div>
      </div>

      <div className="container navMT">
        <nav className="navbar navbar-expand-lg navbar-light w-100">
          <div className="text-left position-relative">
            <a href="https://rewards.oilchangers.com/">
              <span className="headerlogo"></span>
            </a>
            {/* <span className='headerRR pl-3'>REWARDS</span>                 */}
          </div>
          <button
            id="menu-bar"
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
            {/* <i class="fa fa-bars" aria-hidden="true"></i> */}
          </button>
          <div className="collapse navbar-collapse ml-auto" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item ">
                <span className="headerBackLink">
                  <a href="https://oilchangers.com/">Back to Oil Changers</a>
                </span>
              </li>
            </ul>
          </div>
          {/* {isLoggedIn ? ( <div className="collapse navbar-collapse ml-auto" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">

                            <li className="nav-item dView">
                                <a className="nav-link active" aria-current="page" href="#">Back to Rewards</a>
                            </li>

                            <li className="nav-item dView">
                            <a className="nav-link" aria-current="page" onClick={() => redirectToUpdateProfile()} >Profile</a>

                           </li>

                            <li className="nav-item dView">
                                <a className="nav-link" aria-current="page" onClick={() => handleLogout()}>Sign Out</a>
                            </li>

                            <li className="nav-item dropdown mViewNone">
                                {isLoggedIn ? (
                                    <div className="dropdown">
                                    <span className="dotDrop"> {loginUser}</span>
                                    <button className="btn btn-secondary dropdown-toggle cstmdropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                                    </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                            <button className="dropdown-item btnDestop" type="button">Back to Rewards</button>
                                            <button className="dropdown-item" type="button" onClick={() => redirectToUpdateProfile()} >Profile</button>{}
                                            <button className="dropdown-item" type="button" onClick={() => handleLogout()}>Sign Out</button>
                                        </div>
                                    </div>

                                    ) : (
                                    <span className='headerBackLink'><a href="https://oilchangers.com/" >Back to Oil Changers</a></span>
                                )}
                            </li>
                        </ul>
                    </div>
                      ):(<div className="collapse navbar-collapse ml-auto" id="navbarSupportedContent">
                      <ul className="navbar-nav ml-auto">

                          <li className="nav-item ">
                          <span className='headerBackLink'><a href="https://oilchangers.com/" >Back to Oil Changers</a></span>
                          </li>


                      </ul>
                  </div>)} */}
        </nav>
      </div>

      {/* <nav className="navbar navbar-dark navbarWidth">
                <div className="row pl-3 w-100 d-flex justify-content-center text-white">
                    <div className="col-6 text-left mt-3">
                        <span className="headerlogo"></span>
                        <span className='headerR pl-3'>REWARDS</span>
                    </div>
                    <div className="col-6 text-right">
                        {isLoggedIn ? (
                            <div className="dropdown">
                            <span className="dotDrop"> {loginUser}</span>
                            <button className="btn btn-secondary dropdown-toggle cstmdropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                <button className="dropdown-item" type="button" onClick={() => redirectToUpdateProfile()} >Profile</button>{}
                                <button className="dropdown-item" type="button" onClick={() => handleLogout()}>Sign Out</button>
                            </div>
                            </div>

                            ) : (
                            <span className='headerBackLink'><a href="https://oilchangers.com/" >Back to Oil Changers</a></span>
                        )}

                    </div>
                </div>
            </nav> */}
    </div>
  );
}
export default withRouter(Header);
