import React, { useState } from "react";
import axios from "axios";
import "./LoginForm.css";
import { API_BASE_URL, ACCESS_TOKEN_NAME, WRAP_API_BASE_URL, DEVELOPER_KEY_TOKEN, DEVICE_TOKEN } from "../../constants/apiConstants";
import { checkValidationFormAllControls } from "../../constants/validation";
import { withRouter } from "react-router-dom";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function LoginForm(props) {
  // localStorage.clear();
  const [responseState, setResponceS] = useState([]);

  const [state, setState] = useState({
    email: localStorage.getItem("uData") ? localStorage.getItem("uData").split("~")[0] : "",
    password: localStorage.getItem("uData") ? localStorage.getItem("uData").split("~")[1] : "",
    successMessage: null,
    sms_opt_out: false,
  });
  const [objError, setObjError] = useState("");
  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  const [sms_opt_out, setPass] = React.useState("");

  const handleChangeTick = (event) => {
    setPass(event.target.checked);
    if (event.target.checked) {
      localStorage.setItem("SetPass", "1");
    } else {
      localStorage.setItem("SetPass", "0");
    }
    console.log(sms_opt_out + ">>>>>>" + event.target.checked);
  };
  const forgotPassword = () => {
    if (!state.mobileno || state.mobileno.length < 5) {
      alert("Please enter valid mobile no");
      return;
    }

    document.getElementById("closeBTN").click();

    const payload = {
      phone: state.mobileno,
      // "password": state.password,
    };
    const headers = {
      "Content-Type": "application/json",
      // Authorization: "Token token=--WEBRmfcyzgRpxrWEB--",
      Authorization: DEVICE_TOKEN,
    };
    //   members/forgot_password
    axios
      .post(WRAP_API_BASE_URL + "/member/forgot_password", payload, {
        headers: headers,
      })
      .then(function (response) {
        if (response.status === 200) {
          console.log(JSON.stringify(response));
          setState((prevState) => ({
            ...prevState,
            successMessage: response.data.message,
          }));
          setResponceS(response);

          localStorage.setItem("data", JSON.stringify(response));
          // localStorage.setItem(ACCESS_TOKEN_NAME, response.data.apikey);

          setTimeout(() => {
            props.showError(null);
            // props.history.push('/home',{state:response.data})
            //redirectToHome();
          }, 2000);

          props.showError(null);
        } else if (response.code === 204) {
          props.showError("Username and password do not match");
        } else {
          props.showError("Username does not exists");
        }
      })
      .catch(function (error) {
        props.showError("Username and password do not match");
      });
  };

  const handleSubmitClick = (e) => {
    // setTimeout(() => {
    //     props.history.push('/UpdateProfileForm',{state:homeData});
    //     //redirectToHome();
    // }, 2000);
    // return

    e.preventDefault();

    var objValidation = checkValidationFormAllControls(document.forms[0].elements, false, []);
    if (objValidation.valid !== false) {
      setObjError(objValidation);
      return;
    }
    const payload = {
      email: state.email,
      password: state.password,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Token token=--WEBRmfcyzgRpxrWEB--",
    };

    axios
      .post(API_BASE_URL + "/members/auth", payload, {
        headers: headers,
      })
      .then(function (response) {
        if (response.status === 200) {
          setState((prevState) => ({
            ...prevState,
            successMessage: "Login successful. Redirecting to home page..",
          }));
          setResponceS(response);

          localStorage.setItem("data", JSON.stringify(response));
          // alert(localStorage.getItem('SetPass'))
          if (localStorage.getItem("SetPass") == "1") {
            localStorage.setItem("uData", state.email + "~" + state.password);
          }
          localStorage.setItem(ACCESS_TOKEN_NAME, response.data.apikey);

          setTimeout(() => {
            props.history.push("/home", { state: response.data });
            //redirectToHome();
          }, 500);

          props.showError(null);
        } else if (response.code === 204) {
          props.showError("Username and password do not match");
        } else {
          props.showError("Username does not exists");
        }
      })
      .catch(function (error) {
        props.showError("Username and password do not match");
        console.log("Username and password do not match");
      });
    console.log(props);
  };
  const redirectToHome = () => {
    console.log("updated");
    props.updateTitle("Home");

    props.history.push("/home");
  };
  const redirectToRegister = () => {
    props.history.push("/register");
    props.updateTitle("Register");
  };
  const theme = createTheme();
  return (
    <ThemeProvider theme={theme}>
      <Container className="marginTopDiv" component="main" maxWidth="xs">
        <div className="alert alert-success mt-2" style={{ display: state.successMessage ? "block" : "none" }} role="alert">
          {state.successMessage}
        </div>
        <CssBaseline />
        <Box component="form" id="login" name="login" noValidate sx={{ mt: 1 }}>
          <div className="form-group "> Sign In </div>
          <div className="form-group text-left ">
            <TextField
              margin="none"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              type="email"
              autoComplete="email"
              autoFocus
              value={state.email}
              onChange={handleChange}
              className="textFileld"
            />
            <span
              style={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {objError.email}
            </span>
            {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
          </div>

          <div className="form-group text-left">
            <TextField
              margin="none"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={state.password}
              onChange={handleChange}
            />
            <span
              style={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {objError.password}
            </span>
          </div>

          <div className="form-check"></div>
          <div className="form-group text-left">
            <Checkbox
              margin="none"
              id="sms_opt_out"
              label="Enter sms opt out"
              name="sms_opt_out"
              autoComplete="sms_opt_out"
              autoFocus
              value={state.sms_opt_out}
              onChange={handleChangeTick}
            />
            Remember me
            <span
              className="forgotpassword"
              // onClick={forgotPassword}
            >
              {" "}
              <button type="button" className="btn btn-link forgotpassword" data-toggle="modal" data-target="#exampleModal">
                Forgot Password?
              </button>
            </span>
            {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
          </div>
          <Button
            // type="submit"
            size="large"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            className="buttonStyle"
            onClick={redirectToRegister}
          >
            SIGN UP
          </Button>
          <Button
            type="submit"
            size="large"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleSubmitClick}
            className="buttonStyleRight"
          >
            SIGN IN
          </Button>

          {/* <div className="registerMessage">
                        <span>Dont have an account? </span>
                        <span className="loginText" onClick={() => redirectToRegister()}>Register</span>
                    </div> */}
        </Box>
      </Container>
      <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Forgot Password
              </h5>
              <button id="closeBTN" type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <TextField
                margin="none"
                required
                fullWidth
                name="Mobile no"
                label="Mobile no"
                type="text"
                id="mobileno"
                autoComplete="current-password"
                value={state.mobileno}
                onChange={handleChange}
              />
            </div>
            <div className="modal-footer">
              {/* <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> */}
              {/* <button type="button" onClick={forgotPassword}   className="buttonStyleRight" >submit</button> */}
              <Button
                type="submit"
                size="large"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={forgotPassword}
                className="buttonStyleRight"
              >
                SUBMIT
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default withRouter(LoginForm);
