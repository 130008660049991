
import React from 'react';
import { withRouter } from "react-router-dom";
import { ACCESS_TOKEN_NAME } from '../../constants/apiConstants';
function Footer(props) {
   
   
    return(
        <div className="Footer"> 
            <div className="levelone"> <img src="../logo_w_tagline.svg" ></img></div>
            <div className="leveltwo"><p>© 2022 Oil Changers. All Rights Reserved.</p> </div>
        </div>
    )
}

export default withRouter(Footer);


