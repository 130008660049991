import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import axios from "axios";
import { DEVELOPER_KEY_TOKEN, LOCATION_KEYS, DEVICE_KEY } from "../../constants/apiConstants";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Button, Modal, Typography, Grid } from "@mui/material";
import Moment from "moment";
import { API_BASE_URL } from "../../constants/apiConstants";
import AlertComponent from "../AlertComponent/AlertComponent";
import "./Home.css";

const Box = styled(Paper)(({ theme }) => ({ width: "100%", marginTop: "2%", marginBottom: "2%" }));

function Home(props) {
  const data = JSON.parse(localStorage.getItem("data")).data;
  var [offerData, setOfferData] = useState([]);
  var [points, setPoints] = useState("--");
  var [modalIndex, setModalIndex] = useState(null);
  var [confirmModalIndex, setConfirmModalIndex] = useState(null);
  var [directionsModal, setDirectionsModal] = useState(null);
  var [isRedeeming, setIsRedeeming] = useState(false);
  var [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    axios
      .get(API_BASE_URL + "/promotions", {
        headers: {
          "Content-Type": "application/json",
          Authorization: DEVELOPER_KEY_TOKEN + ":" + localStorage.getItem("login_access_token"),
        },
      })
      .then(async function (response) {
        if (response.status === 200) {
          const location = response.data.locations.find((l) => LOCATION_KEYS.indexOf(l.apikey) > -1);
          if (location) {
            setOfferData(location.promotions.redeemable);
            setPoints(location.member_points);
          }
        }
      })
      .catch(function (error) {
        //redirectToLogin()
      });
  }, []);

  const redeemOffer = (i) => {
    setIsRedeeming(true);

    axios
      .post(
        API_BASE_URL + "/promotions/" + offerData[i].apikey + "/redeem",
        {
          pin: "20493572048",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token token=" + DEVICE_KEY + ":" + localStorage.getItem("login_access_token"),
          },
        }
      )
      .then(async function (response) {
        if (response.status === 200) {
          // Remove from offers
          const apikey = offerData[i].apikey;
          setDirectionsModal(offerData[i]);
          setTimeout(() => {
            setDirectionsModal((obj) => (obj.apikey === apikey ? null : obj));
          }, 10 * 60 * 1000); // Autoclose after 10 minutes

          setConfirmModalIndex(null);
          setOfferData(offerData.slice(0, confirmModalIndex).concat(offerData.slice(confirmModalIndex + 1)));
          setPoints(response.data.remaining_points);
        }
      })
      .catch(function (error) {
        setErrorMessage("Unable to redeem promotion");
      })
      .then(() => {
        setConfirmModalIndex(null);
        setIsRedeeming(false);
      });
  };

  return (
    <Box>
      <div className="row marginTopDiv">
        <div className="grid-col commonDiv">
          <label>Welcome,</label>
          <span>
            {" "}
            {data.first_name} {data.last_name}!
          </span>
        </div>
      </div>
      <div className="row">
        <div className="grid-col commonDiv">
          <div className="">
            <label>You have</label>
          </div>
          <div className="row w100">
            <div className="points">
              <span className="pointSpan">{points}</span>
              <label className="ponitsLable">POINTS</label>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className={"col col-total grid-col"}> Offers</div>
      </div>

      <div className="row mainOfferDiv">
        {!offerData.length && (
          <div>
            <em>No offers available at this time.</em>
          </div>
        )}
        {offerData.map((res, i) => {
          // Return the element. Also pass key

          return (
            <div
              className={"col-md-3 col-sm-6 col-12 pointer"}
              key={i}
              onClick={() => {
                setModalIndex(i);
              }}
            >
              <div className="offer">
                {" "}
                <img src="../offer.png" />{" "}
              </div>
              <div className="cardDiv">
                <h5 className={"align-h"}>{res.name}</h5>
                <p className="descriptionText">{res.description}</p>
                <label className="expireFont">
                  Expires : <span>{res.expire_date ? Moment(res.expire_date).format("MM/DD/YYYY") : <i>No Expiration Date</i>}</span>
                </label>
              </div>
            </div>
          );
        })}
      </div>

      <Modal
        open={modalIndex !== null}
        onClose={() => {
          setModalIndex(null);
        }}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        {modalIndex !== null && (
          <Box sx={{ position: "absolute", maxWidth: 400, left: "50%", top: 50, transform: `translate(-50%, 0)` }}>
            <Typography id="modal-title" variant="h6" component="h2" sx={{ mx: 2, mt: 1, mb: 2 }}>
              {offerData[modalIndex].name}
            </Typography>
            <Typography sx={{ mx: 2, mb: 1 }}>{offerData[modalIndex].details}</Typography>
            {!!offerData[modalIndex].cost && (
              <Typography sx={{ mx: 2, fontSize: 14 }}>Point Value: {offerData[modalIndex].cost}</Typography>
            )}
            {offerData[modalIndex].expire_date && (
              <Typography sx={{ mx: 2, fontSize: 14 }}>
                Expiration Date: {Moment(offerData[modalIndex].expire_date).format("MM/DD/YYYY")}
              </Typography>
            )}
            <Grid container spacing={2} sx={{ mb: 2, mt: 1 }} justifyContent="space-evenly">
              <Grid item>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  className="genericButton"
                  onClick={() => {
                    setModalIndex(null);
                  }}
                >
                  CANCEL
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  className="genericButton"
                  onClick={() => {
                    setConfirmModalIndex(modalIndex);
                    setModalIndex(null);
                  }}
                >
                  REDEEM OFFER
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Modal>

      <Modal
        open={confirmModalIndex !== null}
        onClose={() => {
          if (!isRedeeming) {
            setConfirmModalIndex(null);
          }
        }}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        {confirmModalIndex !== null && (
          <Box sx={{ position: "absolute", maxWidth: 400, left: "50%", top: 50, transform: `translate(-50%, 0)` }}>
            <Typography sx={{ mx: 2, mb: 1, mt: 2 }}>
              Are you sure you want to redeem {offerData[confirmModalIndex].name}
              {offerData[confirmModalIndex].expire_date && (
                <> that expires on {Moment(offerData[confirmModalIndex].expire_date).format("MM/DD/YYYY")}</>
              )}
              ?
            </Typography>
            <Typography sx={{ mx: 2, mb: 1, mt: 2 }}>It's important to redeem this offer in front of the cashier.</Typography>
            <Grid container spacing={2} sx={{ mb: 2, mt: 1 }} justifyContent="space-evenly">
              <Grid item>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  className="genericButton"
                  disabled={isRedeeming}
                  onClick={() => {
                    setConfirmModalIndex(null);
                  }}
                >
                  CANCEL
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  className="genericButton"
                  disabled={isRedeeming}
                  onClick={() => {
                    redeemOffer(confirmModalIndex);
                  }}
                >
                  REDEEM OFFER
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Modal>

      <Modal
        open={directionsModal !== null}
        onClose={() => setDirectionsModal(null)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        {directionsModal !== null && (
          <Box sx={{ position: "absolute", maxWidth: 400, left: "50%", top: 50, transform: `translate(-50%, 0)` }}>
            <Typography id="modal-title" variant="h6" component="h2" sx={{ mx: 2, mt: 1, mb: 2 }}>
              Cashier Instructions
            </Typography>

            <Typography sx={{ mx: 2, mb: 1, mt: 2 }}>
              Show this screen to the cashier.
            </Typography>

            <Typography sx={{ mx: 2, mb: 1, mt: 2 }}>
              <strong>Offer:</strong>
              <br />
              {directionsModal.name}
            </Typography>

            <Typography sx={{ mx: 2, mb: 1, mt: 2 }}>
              <strong>Date Redeemed:</strong>
              <br />
              {new Date().toLocaleDateString("en-us")} {new Date().toLocaleTimeString("en-us")}
            </Typography>

            <Typography sx={{ mx: 2, mb: 1, mt: 2 }}>
              <strong>Instructions:</strong>
              <br />
              {directionsModal.cashier_directions}
            </Typography>

            <Grid container spacing={2} sx={{ mb: 2, mt: 1 }} justifyContent="space-evenly">
              <Grid item>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  className="genericButton"
                  onClick={() => {
                    setDirectionsModal(null);
                  }}
                >
                  DONE
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Modal>

      {errorMessage && <AlertComponent errorMessage={errorMessage} hideError={setErrorMessage} />}
    </Box>
  );
}

export default withRouter(Home);
