import React, { useState } from 'react';
import axios from 'axios';
import { checkValidationFormAllControls } from '../../constants/validation';
import { API_BASE_URL, ACCESS_TOKEN_NAME ,DEVICE_TOKEN,DEVELOPER_KEY_TOKEN} from '../../constants/apiConstants';
import { withRouter } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
// import Box from '@mui/material/Box';




function UpdateProfileForm(props) {
    const [objError, setObjError] = useState('')
    // const [dataObj, setStateData] = useState(JSON.parse(localStorage.getItem("data")))
    console.log(props);
    // var objData = props.location.state.state
    var objData = JSON.parse(localStorage.getItem("data")).data
    var stateObj = {
        fname: objData.first_name,
        lname: objData.last_name,
        phone: objData.phone,
        email: objData.email,// need to remove
        postal_code: objData.postal_code,
        address1: objData.address1,
        address2: objData.address2,
        city: objData.city,
        state: objData.state,
        country: objData.country,
        birthday: objData.birthday,
        gen: objData.gender,


    }
    var stateLit = ['Alabama',
        'Alaska',
        'Arizona',
        'Arkansas',
        'California',
        'Colorado',
        'Connecticut',
        'Delaware',
        'Florida',
        'Georgia',
        'Hawaii',
        'Idaho',
        'IllinoisIndiana',
        'Iowa',
        'Kansas',
        'Kentucky',
        'Louisiana',
        'Maine',
        'Maryland',
        'Massachusetts',
        'Michigan',
        'Minnesota',
        'Mississippi',
        'Missouri',
        'MontanaNebraska',
        'Nevada',
        'New Hampshire',
        'New Jersey',
        'New Mexico',
        'New York',
        'North Carolina',
        'North Dakota',
        'Ohio',
        'Oklahoma',
        'Oregon',
        'PennsylvaniaRhode Island',
        'South Carolina',
        'South Dakota',
        'Tennessee',
        'Texas',
        'Utah',
        'Vermont',
        'Virginia',
        'Washington',
        'West Virginia',
        'Wisconsin',
        'Wyoming']
    const [gender, setAge] = React.useState(objData.gender);

    const handleChangeGen = (event) => {

        setAge(event.target.value);

        console.log(gender + '>>>>>>' + event.target.value);

    };

    const [state, setState] = useState(stateObj)

    const handleChange = (e) => {
        const { id, value } = e.target
        console.log('id select value',id);
        console.log('value select value',value
        );

        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }
    const handleChangeSate = (event) => {

        const { name, value } = event.target
        setState(prevState => ({
            ...prevState,
            [name]:  value
        }))
    }

    const sendDetailsToServer = () => {
        if (state.fname.length && state.fname.length) {
            props.showError(null);
            const payload = {
                "email": state.email, // need to check
                "password": state.password, // need to check
                "first_name": state.fname,
                "last_name": state.lname,
                "postal_code": state.postal_code,
                "phone": state.phone,
                "address1": state.address1,
                "address2": state.address2,
                "city": state.city,
                "state": state.state,
                "country": state.country,
                "birthday": state.birthday,
                "anniversary": state.anniversary,
                "gender": state.gen,
                "sms_opt_out": state.sms_opt_out,
                "password_confirmation": state.password, // need to check

            }
            const headers = {
                'Content-Type': 'application/json',
                // 'Authorization': 'Token token=--WEBRmfcyzgRpxrWEB--' + ':' + objData.apikey
                'Authorization': DEVICE_TOKEN + ':' + objData.apikey
            }

            axios.put(API_BASE_URL + '/members', payload, {
                headers: headers
            })
                .then(function (response) {
                    if (response.status === 200) {

                        if(response.data.message)
                        {
                            props.showError(response.data.message);
                            return
                        }


                        setState(prevState => ({
                            ...prevState,
                            'successMessage': 'Registration successful. Redirecting to home page..'
                        }))
                        console.log(response.data.apikey);
                        localStorage.setItem(ACCESS_TOKEN_NAME, response.data.apikey);
                        var localStoragedata = JSON.parse(localStorage.getItem('data'))
                        localStoragedata.data = response.data
                        localStorage.setItem('data', JSON.stringify(localStoragedata));
                        setTimeout(() => {
                            props.updateTitle('Update Profile')
                            //    var  phoneno= response.data.phone
                            var jsonDatavar = {
                                phone: state.phone
                            }
                            props.history.push('/home', { state: jsonDatavar })
                            if(localStorage.getItem('SetPass') == '1'){
                                localStorage.setItem('uData',state.email+'~'+state.password);
                                }
                            //redirectToHome();
                        }, 2000);
                        props.showError(null)
                    } else {
                        props.showError("Some error ocurred");
                    }
                })
                .catch(function (error) {
                    if(error.request)
                    {
                        props.showError(null)
                        setObjError('');
                        props.showError(error.response.data.error == 'Email taken'?'Email already exist':error.response.data.error);
                        console.log( 'request  ',error.request)
                        console.log( 'request data ',error.response.data.error)
                    }else{
                        console.log('message', error.message)
                    }
                    console.log(error);
                });
        } else {
            props.showError('Please enter valid username and password')
        }

    }
    const redirectToHome = () => {
        props.updateTitle('Home')
        props.history.push('/home');
    }


    const handleSubmitClick = (e) => {
        e.preventDefault();
        var objValidation = checkValidationFormAllControls(document.forms[0].elements, false, [])
        if (objValidation.valid !== false) {
            setObjError(objValidation);
            //setEmailError(objValidation['email']);
            //PasswordsetError(objValidation['password']);
            return;
        }


        sendDetailsToServer()  //
    }
    const backToHome = () => {
        props.updateTitle('Profile')
        props.history.push('/profilePage');
    }


    const theme = createTheme();
    return (
        <ThemeProvider theme={theme}>
            <Container className=" marginTopDiv" component="main" maxWidth="">
                <div className="alert alert-success mt-2" style={{ display: state.successMessage ? 'block' : 'none' }} role="alert">
                    {state.successMessage}
                </div>
                <CssBaseline />
                <div className="profileTitle pl-0">Update Your Profile </div>
                <Box component="form" className="updateForm" noValidate sx={{ mt: 1 }}>
                    <div className="form-group text-left row">
                        <div className="form-group text-left col-6">

                            <TextField margin="none"
                                required
                                fullWidth
                                id="fname"
                                name="first_name"
                                autoComplete="first_name"
                                autoFocus
                                label="First name"
                                value={state.fname}
                                onChange={handleChange}
                            />
                            {objError.first_name ?
                                <span style={{
                                    fontWeight: 'bold',
                                    color: 'red',
                                }}>{objError.first_name}</span>

                                : ''
                            }
                        </div>

                        <div className="form-group text-left col-6">

                            <TextField margin="none"
                                required
                                fullWidth
                                id="lname"
                                name="last_name"
                                autoComplete="lname"
                                autoFocus
                                label="Last Name"
                                value={state.lname}
                                onChange={handleChange}
                            />
                            {objError.lname ? <span style={{
                                fontWeight: 'bold',
                                color: 'red',
                            }}>{objError.last_name}</span> : ''}
                        </div>


                    </div>
                    <div className="form-group text-left">

                        <TextField margin="none"
                            required
                            fullWidth
                            id="email"
                            type="email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            label="Email"
                            value={state.email}
                            onChange={handleChange}
                        />
                        {objError.email ?
                            <span style={{
                                fontWeight: 'bold',
                                color: 'red',
                            }}>{objError.email}</span> : ''}
                    </div>

                    <div className="form-group text-left">

                        <TextField margin="none"
                            required
                            fullWidth
                            name="password"
                            autoComplete="password"
                            autoFocus
                            id="password"
                            label="Password"
                            type="password"
                            value={state.password}
                            pattern="^(?=.[a-z])(?=.[A-Z])(?=.[0-9])(?=.[!@#\$%\^&\*])(?=.{8,})"


                            onChange={handleChange}
                            inputProps={{
                                match: "password,confirmPassword"

                            }}
                        />

                        {objError.password ?
                            <span style={{
                                fontWeight: 'bold',
                                color: 'red',
                            }}>{objError.password}</span> : ''}

                    </div>


                    {/* <div className="form-group text-left">

                        <TextField margin="none"
                            required
                            fullWidth
                            name="password"
                            autoComplete="password"
                            autoFocus
                            id="password"
                            label="Password"
                            type="password"
                            value={state.password}
                            pattern="^(?=.[a-z])(?=.[A-Z])(?=.[0-9])(?=.[!@#\$%\^&\*])(?=.{8,})"


                            onChange={handleChange}
                            inputProps={{
                                match: "password,confirmPassword"

                            }}
                        />



                    </div>
                    {objError.password ?
                        <span style={{
                            fontWeight: 'bold',
                            color: 'red',
                        }}>{objError.password}</span> : ''} */}


                    <div className="form-group text-left">

                        <TextField margin="none"
                            required
                            fullWidth
                            inputProps={{
                                maxLength: 15,
                                minLength: 10
                            }}
                            id="phone"
                            name="phone"
                            autoComplete="phone"
                            autoFocus
                            type="text"
                            label="Phone no"
                            value={state.phone}
                            onChange={handleChange}
                        />
                        {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                        {objError.phone ?
                            <span style={{
                                fontWeight: 'bold',
                                color: 'red',
                            }}>{objError.phone}</span> : ''}
                    </div>







                    <Grid container spacing={2}>
                        {/* <Grid item xs={12} md={4}>
                            <div className="form-group text-left">

                                <TextField margin="none"
                                    required
                                    fullWidth
                                    id="fname"
                                    name="first_name"
                                    autoComplete="first_name"
                                    autoFocus
                                    label="First name"
                                    value={state.fname}
                                    onChange={handleChange}
                                />
                            </div>
                            {objError.first_name ?
                                <span style={{
                                    fontWeight: 'bold',
                                    color: 'red',
                                }}>{objError.first_name}</span>

                                : ''
                            }

                        </Grid>

                        <Grid item xs={12} md={4}>

                            <div className="form-group text-left">

                                <TextField margin="none"
                                    required
                                    fullWidth
                                    id="lname"
                                    name="last_name"
                                    autoComplete="lname"
                                    autoFocus
                                    label="Last Name"
                                    value={state.lname}
                                    onChange={handleChange}
                                />

                            </div>
                            <span style={{
                                fontWeight: 'bold',
                                color: 'red',
                            }}>{objError.last_name}</span>
                        </Grid> */}

                        {/* <Grid item xs={12} md={4}>

                            <div className="form-group text-left">

                                <TextField margin="none"
                                    required
                                    fullWidth
                                    id="email"
                                    type="email"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    label="Email"
                                    value={state.email}
                                    onChange={handleChange}
                                />

                            </div>
                            {objError.email ?
                                <span style={{
                                    fontWeight: 'bold',
                                    color: 'red',
                                }}>{objError.email}</span> : ''}

                        </Grid> */}
                        {/*
                        <Grid item xs={12} md={4}>
                            <div className="form-group text-left">
                                <TextField margin="none"
                                    required
                                    fullWidth
                                    inputProps={{
                                        maxLength: 10,
                                        minLength: 10
                                    }}
                                    id="phone"
                                    name="phone"
                                    autoComplete="phone"
                                    autoFocus
                                    type="tel"
                                    label="Phone no"
                                    value={state.phone}
                                    onChange={handleChange}
                                />


                                {objError.phone ?
                                <span style={{
                                    fontWeight: 'bold',
                                    color: 'red',
                                }}>{objError.phone}</span> : ''}
                            </div>


                        </Grid> */}



                        {/* <Grid item xs={12} md={4}>
                            <div className="form-group text-left">

                                <TextField margin="none"
                                    required
                                    fullWidth
                                    name="password"
                                    autoComplete="password"
                                    autoFocus
                                    id="password"
                                    label="Password"
                                    type="password"
                                    value={state.password}
                                    pattern="^(?=.[a-z])(?=.[A-Z])(?=.[0-9])(?=.[!@#\$%\^&\*])(?=.{8,})"


                                    onChange={handleChange}
                                    inputProps={{
                                        match: "password,confirmPassword"

                                    }}
                                />
                            </div>
                            {objError.password ?
                                <span style={{
                                    fontWeight: 'bold',
                                    color: 'red',
                                }}>{objError.password}</span> : ''}

                        </Grid> */}

                        {/* <Grid item xs={12} md={4}>

                            <div className="form-group text-left">

                                <TextField margin="none"
                                    required
                                    fullWidth
                                    id="confirmPassword"
                                    type="password"
                                    name="confirmPassword"
                                    autoComplete="confirmPassword"
                                    autoFocus
                                    pattern="^(?=.[a-z])(?=.[A-Z])(?=.[0-9])(?=.[!@#\$%\^&\*])(?=.{8,})"
                                    inputProps={{
                                        match: "password,confirmPassword"

                                    }}

                                    label="Confirm Password"
                                    value={state.confirmPassword}
                                    onChange={handleChange}

                                />
                            </div>
                            {objError.confirmPassword ?
                                <span style={{
                                    fontWeight: 'bold',
                                    color: 'red',
                                }}>{objError.confirmPassword}</span> : ''}

                        </Grid> */}

                        <Grid item xs={12} md={12}>

                            <div className="form-group text-left">
                                <TextField margin="none"
                                    required
                                    fullWidth
                                    id="address1"
                                    name="first_address"
                                    autoComplete="address1"
                                    autoFocus
                                    label="Address"
                                    value={state.address1}
                                    onChange={handleChange}
                                />

                                <span style={{
                                    fontWeight: 'bold',
                                    color: 'red',
                                }}>{objError.first_address}</span>
                            </div>

                        </Grid>

                        {/* <Grid item xs={12} md={4}>

                            <div className="form-group text-left">
                                <TextField margin="none"
                                    required
                                    fullWidth
                                    id="address2"
                                    name="second_address"
                                    autoComplete="address2"
                                    autoFocus
                                    label="Address"
                                    value={state.address2}
                                    onChange={handleChange}
                                />


                            </div>

                            <span style={{
                                fontWeight: 'bold',
                                color: 'red',
                            }}>{objError.second_address}</span>

                        </Grid> */}

                        <Grid item xs={12} md={6}>
                            <div className="form-group text-left">
                                <TextField margin="none"
                                    required
                                    fullWidth
                                    id="city"

                                    name="city"
                                    autoComplete="city"
                                    autoFocus
                                    label="City"
                                    value={state.city}
                                    onChange={handleChange}
                                />

                                <span style={{
                                    fontWeight: 'bold',
                                    color: 'red',
                                }}>{objError.city}</span>
                            </div>

                        </Grid>

                        <Grid item xs={12} md={6}>
                            <div className="form-group customState text-left">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">State</InputLabel>
                                    <Select
                                        required
                                        fullWidth
                                        id="state"
                                        label="State"
                                        name="state"
                                        autoComplete="state"
                                        autoFocus

                                        value={state.state}
                                        onChange={handleChangeSate}
                                    >


                                        {stateLit.map((res, i) => {

                                            // Return the element. Also pass key

                                            return (
                                                <MenuItem value={res}>{res}</MenuItem>

                                            )
                                        })}

                                    </Select>
                                </FormControl>

                                {/* <TextField margin="none"
                                    required
                                    fullWidth
                                    id="state"
                                    label="State"
                                    name="state"
                                    autoComplete="state"
                                    autoFocus

                                    value={state.state}
                                    onChange={handleChange}
                                /> */}
                                <span style={{
                                    fontWeight: 'bold',
                                    color: 'red',
                                }}>{objError.state}</span>
                            </div>


                        </Grid>


                        <Grid item xs={12} md={6}>
                            <div className="form-group text-left">
                                <TextField margin="none"
                                    required
                                    fullWidth
                                    id="postal_code"
                                    label="Zip code"
                                    name="postal_code"
                                    autoComplete="postal_code"
                                    autoFocus
                                    type="tel"
                                    inputProps={{
                                        maxLength: 5,
                                        minLength: 5
                                    }}
                                    value={state.postal_code}
                                    onChange={handleChange}
                                />
                                {objError.postal_code ?
                                    <span style={{
                                        fontWeight: 'bold',
                                        color: 'red',
                                    }}>{objError.postal_code}</span> : ''}
                            </div>

                        </Grid>

                        <Grid item xs={12} md={6}></Grid>


                        <Grid item xs={12} md={6}>
                            <div className="form-group text-left">
                                <TextField margin="none"
                                    required
                                    fullWidth
                                    id="country"
                                    label="Country"
                                    name="country"
                                    autoComplete="country"
                                    autoFocus
                                    disabled
                                    value={state.country}
                                    onChange={handleChange}
                                />

                                {objError.country ? <span style={{
                                    fontWeight: 'bold',
                                    color: 'red',
                                }}>{objError.country}</span> : ''}
                            </div>

                        </Grid>



                        <Grid item xs={12} md={6}>
                            <div className="form-group text-left">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={state.gen}
                                        label="Gender"
                                        name="gen"
                                        onChange={handleChangeSate}
                                    >


                                        <MenuItem value={'male'}>Male</MenuItem>
                                        <MenuItem value={'female'}>Female</MenuItem>
                                        <MenuItem value={'other'}>Other</MenuItem>

                                    </Select>
                                </FormControl>
                                {/* <TextField margin="none"
                                    required
                                    fullWidth
                                    id="gen"
                                    label="Gender"
                                    name="gender"
                                    autoComplete="gen"
                                    autoFocus

                                    value={state.gen}
                                    onChange={handleChange}
                                /> */}

                                <span style={{
                                    fontWeight: 'bold',
                                    color: 'red',
                                }}>{objError.gender}</span>
                            </div>

                        </Grid>



                    </Grid>

                    {/* <Button className="Update_Button"
                        // style={{ float:'right'}}
                        type="submit"

                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handleSubmitClick}
                    >
                        Save
                    </Button> */}
                    <Button
                        type="submit"
                        size="large"
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        className="buttonStyle"
                        onClick={backToHome}
                    >Cancel</Button>
                    <Button
                        type="submit"

                        size="large"
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handleSubmitClick}
                        className="buttonStyleRight"
                    >Save</Button>


                    <div className="mt-2">
                        {/* <span>Already have an account? </span>
                <span className="loginText" onClick={() => redirectToLogin()}>Login here</span>  */}
                    </div>

                    <Grid item xs={12} md={6}>
                        <div className="form-group  text-left">
                        </div>
                    </Grid>
                </Box>
            </Container>
        </ThemeProvider>
    )
}

export default withRouter(UpdateProfileForm);