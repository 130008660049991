import React, { useState } from 'react';
import axios from 'axios';
import './RegistrationForm.css';
import { checkValidationFormAllControls } from '../../constants/validation';
import { API_BASE_URL, ACCESS_TOKEN_NAME, ADMIN_API_BASE_URL, WRAP_API_BASE_URL, DEVICE_TOKEN, DEVELOPER_KEY_TOKEN } from '../../constants/apiConstants';
import { withRouter } from "react-router-dom";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import Checkbox from '@mui/material/Checkbox';

import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import $ from 'jquery';
function RegistrationForm(props) {
  const [state, setState] = useState({
    fname: "",
    lname: "",
    phone: "",
    email: "",
    postal_code: "",
    password: "",
    confirm_password: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "United States",
    birthday: "",
    gen: "",
    sms_opt_out: "",

  })

  const [gender, setAge] = React.useState('');

  const handleChangeGen = (event) => {

    setAge(event.target.value);

    console.log(gender + '>>>>>>' + event.target.value);

  };

  var stateLit = ['Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'IllinoisIndiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'MontanaNebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'PennsylvaniaRhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming']

  const [nameError, setNameError] = useState('')
  const [objError, setObjError] = useState('')
  const handleChangeSate = (event) => {

    const { name, value } = event.target

    setState(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleChange = (e) => {
    const { id, value } = e.target
    if (e.target.id == 'birthday') {
      var val = value.split('/')[0] + '/' + value.split('/')[1];
      if (!value.split('/')[1]) {
        val = ''
      }
      setState(prevState => ({
        ...prevState,
        [id]: val
      }))
    } else {
      setState(prevState => ({
        ...prevState,
        [id]: value
      }))
    }

  }

  const handleDateChange = (e) => {
    setState(prevState => ({
      ...prevState,
      birthday: e
    }))

  }

  const sendDetailsToServer = () => {
    if (state.email.length && state.password.length) {
      props.showError(null);
      const payload = {
        "email": state.email,
        "password": state.password,
        "first_name": state.fname,
        "last_name": state.lname,
        "postal_code": state.postal_code,
        "phone": state.phone,
        "address1": state.address1,
        "address2": state.address2,
        "city": state.city,
        "state": state.state,
        "country": state.country,
        "birthday": state.birthday.format('MM/DD'),
        "anniversary": state.anniversary,
        "gender": state.gen,
        "sms_opt_out": state.sms_opt_out,
        "password_confirmation": state.confirm_password,

      }


      const headers = {
        'Content-Type': 'application/json',
        //    'Authorization': DEVELOPER_KEY_TOKEN
        'Authorization': DEVICE_TOKEN

      }

      axios.post(WRAP_API_BASE_URL + '/member', payload, {
        headers: headers
      })
        .then(function (response) {
          if (response.status === 200) {

            if (response.data.token) {
              //.setItem(ACCESS_TOKEN_NAME, response.data.token);
            }
            if (response.data.status == 0) {
              //  alert(response.data.message)
              // props.showError(error.response.data.error == 'Phone has already been taken'?'Phone number already exist':error.response.data.error);
              props.showError(response.data.message)
              return
            }
            else {
              setState(prevState => ({
                ...prevState,
                'successMessage': 'Registration successful. Redirecting to home page..'
              }))

              redirectToVerification()
              props.showError(null)
            }



          } else {
            props.showError(response.error);
          }
        })
        .catch(function (error) {
          // props.showError("Some error ocurred1");
          if (error.request) {
            props.showError(error.response.data.error);
            props.showError(error.response.data.error == 'Phone has already been taken' ? 'Phone number already exist' : error.response.data.error);
            console.log('request  ', error.request)
            console.log('request data ', error.response.data.error)
          } else {
            console.log('message', error.message)
          }
        });
    } else {
      props.showError('Please enter valid username and password')
    }

  }


  const checkDetailsToServer = async () => {

    document.getElementById('formGroup3').style.display = 'block';
    document.getElementById('formGroup4').style.display = 'none';
    return;
    if (state.email.length && state.password.length) {
      props.showError(null);
      const payload = {
        "email": state.email,
        // "password": state.password,
        // "first_name": state.fname,
        // "last_name": state.lname,
        // "postal_code": state.postal_code,
        // "phone": state.phone,
        // "address1": state.address1,
        // "address2": state.address2,
        // "city": state.city,
        // "state": state.state,
        // "country": state.country,
        // "birthday": state.birthday,
        // "anniversary": state.anniversary,
        // "gender": state.gen,
        // "sms_opt_out": state.sms_opt_out,
        // "password_confirmation": state.confirm_password,

      }


      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Token token=--WEBRmfcyzgRpxrWEB--'
      }

      await axios.post(API_BASE_URL + '/members/email_lookup', payload, {
        headers: headers
      })
        .then((response) => {


          if (response.status === 200) {
            // setState(prevState => ({
            //     ...prevState,
            //     'successMessage': 'Registration successful. Redirecting to home page..'
            // }))
            // localStorage.setItem(ACCESS_TOKEN_NAME, response.data.token);
            // redirectToLogin();
            // setObjError(objValidation);
            // console.log(objValidation.valid);
            document.getElementById('formGroup3').style.display = 'block';
            document.getElementById('formGroup4').style.display = 'none';
            props.showError(null)
          } else {

            props.showError(response.error);
          }
        })
        .catch(function (error) {
          if (error.request) {
            props.showError(null)
            setObjError('');
            props.showError(error.response.data.error == 'Email taken' ? 'Email already exist' : error.response.data.error);
            console.log('request  ', error.request)
            console.log('request data ', error.response.data.error)
          } else {
            console.log('message', error.message)
          }

          // props.showError("Some error ocurred1");
        });
    } else {
      props.showError('Please enter valid username and password')
    }
    // const payload = {
    //             "email": state.email
    //         };
    //              const headers = {
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Token token=--WEBRmfcyzgRpxrWEB--'
    //     }
    // fetch('https://api-staging.cinch.io/api/v1/members/email_lookup',{
    // method:'POST',
    // headers: headers,
    // body:JSON.stringify(payload)
    //     }).then((response)=>response.json()).then((responsejson)=>{
    //         console.log('new response of data', responsejson);

    //     }).catch((error)=>{
    //         console.log('error',error);

    //     })

  }





  const redirectToHome = () => {
    props.updateTitle('Home')
    props.history.push('/home');
  }
  const redirectToLogin = () => {
    props.updateTitle('Login')
    props.history.push('/login');
  }
  const redirectToVerification = () => {

    props.history.push('/email-verification');
  }
  const handleNextClickOne = (e) => {
    e.preventDefault();

    var objValidation = checkValidationFormAllControls(document.forms[0].elements, false, [])

    if (objValidation.valid != false) {
      setObjError(objValidation);
      //setEmailError(objValidation['email']);
      //PasswordsetError(objValidation['password']);
      return;
    } else {
      checkDetailsToServer()

    }

  }
  const handleSubmitClick = (e) => {
    e.preventDefault();
    var objValidation = checkValidationFormAllControls(document.forms[1].elements, false, [])
    if (objValidation.valid != false) {
      setObjError(objValidation);

      return;
    }
    sendDetailsToServer()


  }


  const handleBackClick = (e) => {
    e.preventDefault();
    document.getElementById('formGroup3').style.display = 'none';
    document.getElementById('formGroup4').style.display = 'block';
    setObjError('');
  }
  const theme = createTheme();
  return (
    // <div className="card col-12 col-lg-4 login-card mt-2 hv-center">
    <ThemeProvider theme={theme}>
      <Container className="marginTopDiv" component="main" maxWidth="xs">
        <CssBaseline />
        <Box component="form" name="form1" id="form1" noValidate sx={{ mt: 1, mb: 2 }}>
          <div className="form-group "> Sign Up </div>
          <div id="formGroup4">
            <div className="form-group text-left row">
              <div className="form-group text-left col-6">

                <TextField margin="none"
                  required
                  fullWidth
                  id="fname"
                  name="first_name"
                  autoComplete="first_name"
                  autoFocus
                  label="First name"
                  value={state.fname}
                  onChange={handleChange}
                />
                {objError.first_name ?
                  <span style={{
                    fontWeight: 'bold',
                    color: 'red',
                  }}>{objError.first_name}</span>

                  : ''
                }
              </div>

              <div className="form-group text-left col-6">

                <TextField margin="none"
                  required
                  fullWidth
                  id="lname"
                  name="last_name"
                  autoComplete="lname"
                  autoFocus
                  label="Last Name"
                  value={state.lname}
                  onChange={handleChange}
                />
                <span style={{
                  fontWeight: 'bold',
                  color: 'red',
                }}>{objError.last_name}</span>
              </div>


            </div>
            <div className="form-group text-left">

              <TextField margin="none"
                required
                fullWidth
                id="email"
                type="email"
                name="email"
                autoComplete="email"
                autoFocus
                label="Email"
                value={state.email}
                onChange={handleChange}
              />
              {objError.email ?
                <span style={{
                  fontWeight: 'bold',
                  color: 'red',
                }}>{objError.email}</span> : ''}
            </div>

            <div className="form-group text-left">

              <TextField margin="none"
                required
                fullWidth
                inputProps={{
                  maxLength: 10,
                  minLength: 10
                }}
                id="phone"
                name="phone"
                autoComplete="phone"
                autoFocus
                type="tel"
                label="Phone no"
                value={state.phone}
                onChange={handleChange}
              />
              {objError.phone ?
                <span style={{
                  fontWeight: 'bold',
                  color: 'red',
                }}>{objError.phone}</span> : ''}
              {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
            </div>


            {/*<div className="form-group text-left">
                            <TextField margin="none"
                                required
                                fullWidth
                                id="postal_code"
                                label="Enter postal code"
                                name="postal_code"
                                autoComplete="postal_code"
                                autoFocus
                                type="tel"
                                inputProps={{
                                    maxLength: 6,
                                    minLength: 6
                                }}
                                value={state.postal_code}
                                onChange={handleChange}
                            />
                            {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                        </div>*/}
            {/*objError.postal_code ?
                            <span style={{
                                fontWeight: 'bold',
                                color: 'red',
                            }}>{objError.postal_code}</span> : ''*/}

            <div className="form-group text-left">

              <TextField margin="none"
                required
                fullWidth
                name="password"
                autoComplete="password"
                autoFocus
                id="password"
                label="Password"
                type="password"
                value={state.password}



                onChange={handleChange}

                inputProps={{
                  match: "password,confirm_password"

                }}
              />
              {objError.password ?
                <span style={{
                  fontWeight: 'bold',
                  color: 'red',
                }}>{objError.password}</span> : ''}
            </div>

            <div className="form-group text-left">

              <TextField margin="none"
                required
                fullWidth
                id="confirm_password"
                type="password"
                name="confirm_password"
                autoComplete="confirm_password"
                autoFocus
                // pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])){8,15}.+$"
                inputProps={{
                  match: "password,confirm_password"

                }}

                label="Confirm Password"
                value={state.confirm_password}
                onChange={handleChange}

              />
              {objError.confirm_password ?
                <span style={{
                  fontWeight: 'bold',
                  color: 'red',
                }}>{objError.confirm_password}</span> : ''}
            </div>

            {/*<Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleNextClickOne}
                        >
                            Next
                        </Button>*/}
            <Button

              size="large"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              className="buttonStyle"
              onClick={redirectToLogin}
            >SIGN IN</Button>
            <Button
              type="submit"

              size="large"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleNextClickOne}
              className="buttonStyleRight"
            >SIGN UP</Button>
          </div>
        </Box>
        <Box component="form" name="form2" id="form1" noValidate sx={{ mt: 1, mb: 2 }}>
          <div id="formGroup3" >
            <div className="form-group text-left">
              <TextField margin="none"
                required
                fullWidth
                id="address1"
                name="address"
                autoComplete="address1"
                autoFocus
                label="Address 1"
                value={state.address1}
                onChange={handleChange}
              />
              <span style={{
                fontWeight: 'bold',
                color: 'red',
              }}>{objError.address}</span>

            </div>



            <div className="form-group text-left">
              <TextField margin="none"

                fullWidth
                id="address2"
                name="second_address"
                autoComplete="address2"
                autoFocus
                label="Address 2"
                value={state.address2}
                onChange={handleChange}
              />

              <span style={{
                fontWeight: 'bold',
                color: 'red',
              }}>{objError.second_address}</span>
            </div>


            <div className="form-group text-left row">
              <div className="col-6   text-left">
                <TextField margin="none"
                  required
                  fullWidth
                  id="city"

                  name="city"
                  autoComplete="city"
                  autoFocus
                  label="City"
                  value={state.city}
                  onChange={handleChange}
                />
                <span style={{
                  fontWeight: 'bold',
                  color: 'red',
                }}>{objError.city}</span>
                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
              </div>


              <div className="col-6 customState  text-left">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">State</InputLabel>
                  <Select
                    required
                    fullWidth
                    id="state"
                    label="State"
                    name="state"
                    autoComplete="state"
                    autoFocus

                    value={state.state}
                    onChange={handleChangeSate}
                  >


                    {stateLit.map((res, i) => {

                      // Return the element. Also pass key

                      return (
                        <MenuItem value={res}>{res}</MenuItem>

                      )
                    })}

                  </Select>
                </FormControl>
                {/* <TextField margin="none"
                                required
                                fullWidth
                                id="state"
                                label="State"
                                name="state"
                                autoComplete="state"
                                autoFocus

                                value={state.state}
                                onChange={handleChange}
                            /> */}
                <span style={{
                  fontWeight: 'bold',
                  color: 'red',
                }}>{objError.state}</span>
                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
              </div>


            </div>
            <div className="form-group text-left row">
              <div className="  text-left col-6">

                <TextField margin="none"
                  required
                  fullWidth
                  id="postal_code"
                  label="Zip code"
                  name="postal_code"
                  autoComplete="postal_code"
                  autoFocus
                  type="tel"
                  inputProps={{
                    maxLength: 5,
                    minLength: 5
                  }}
                  value={state.postal_code}
                  onChange={handleChange}
                />
                {objError.postal_code ?
                  <span style={{
                    fontWeight: 'bold',
                    color: 'red',
                  }}>{objError.postal_code}</span> : ''}
                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
              </div>

              <div className=" text-left col-6">
                <TextField margin="none"
                  required
                  fullWidth
                  id="country"
                  label="Country"
                  name="country"
                  autoComplete="country"
                  autoFocus
                  disabled
                  value={state.country}
                  onChange={handleChange}
                />
                <span style={{
                  fontWeight: 'bold',
                  color: 'red',
                }}>{objError.country}</span>
                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
              </div>


            </div>





            {/* "address2": state.address2,
                "city": state.city,
                "state": state.state,
                "country": state.country,
                "birthday": state.birthday,
                "anniversary": state.anniversary,
                "gender": state.gender,
                "sms_opt_out": state.sms_opt_out, */}
            <div className="form-group  row">
              <div className="text-left col-6">

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Birthday"
                    views={['month', 'day']}
                    inputFormat="MM/DD"
                    onChange={handleDateChange}
                    value={state.birthday}
                    renderInput={(inputProps) => <TextField
                      margin="none"
                      fullWidth
                      id="birthday"
                      label="Birthday"
                      name="birthday"
                      autoComplete="birthday"
                      InputLabelProps={{
                        shrink: true
                      }}

                      {...inputProps}
                      type="text"
                    />
                    }
                  />
                </LocalizationProvider>


                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}

              </div>


              <div className="form-group text-left col-6">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                  <Select
                    required
                    fullWidth
                    id="gen"
                    label="Gender"
                    name="gen"
                    autoComplete="gen"
                    autoFocus
                    name="gen"
                    value={state.gen}
                    onChange={handleChangeSate}
                  >


                    <MenuItem value={'male'}>Male</MenuItem>
                    <MenuItem value={'female'}>Female</MenuItem>
                    <MenuItem value={'Other'}>Other</MenuItem>

                  </Select>
                </FormControl>

                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                <span style={{
                  fontWeight: 'bold',
                  color: 'red',
                }}>{objError.gen}</span>
              </div>
            </div>


            {<Button
              type="submit"
              className="buttonStyle"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleBackClick}
            >
              Back
            </Button>}

            <Button
              className='buttonStyleRight'
              type="submit"

              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmitClick}
            >
              FINISH
            </Button>

          </div>


          <div className="alert alert-success mt-2" style={{ display: state.successMessage ? 'block' : 'none' }} role="alert">
            {state.successMessage}
          </div>
          { /*<div className="mt-2">
                        <span>Already have an account? </span>
                        <span className="loginText" onClick={() => redirectToLogin()}>Login here</span>
                    </div>*/}

          { /* </div> */}

        </Box>
      </Container>
    </ThemeProvider>
  )
}

export default withRouter(RegistrationForm);