import React, { useState } from "react";
import axios from "axios";
import { checkValidationFormAllControls } from "../../constants/validation";
import { API_BASE_URL, ACCESS_TOKEN_NAME } from "../../constants/apiConstants";
import { withRouter } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
// import Box from '@mui/material/Box';

function ProfilePage(props) {
  const [objError, setObjError] = useState("");
  // const [dataObj, setStateData] = useState(JSON.parse(localStorage.getItem("data")))
  // var objData = props.location.state.state
  var objData = JSON.parse(localStorage.getItem("data")).data;
  var stateObj = {
    fname: objData.first_name,
    lname: objData.last_name,
    phone: objData.phone,
    email: objData.email, // need to remove
    postal_code: objData.postal_code,
    address1: objData.address1,
    address2: objData.address2,
    city: objData.city,
    state: objData.state,
    country: objData.country,
    birthday: objData.birthday,
    gen: objData.gender,
  };
  const [state, setState] = useState(stateObj);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  const sendDetailsToServer = () => {
    if (state.fname.length && state.fname.length) {
      props.showError(null);
      const payload = {
        email: state.email, // need to check
        password: state.password, // need to check
        first_name: state.fname,
        last_name: state.lname,
        postal_code: state.postal_code,
        phone: state.phone,
        address1: state.address1,
        address2: state.address2,
        city: state.city,
        state: state.state,
        country: state.country,
        birthday: state.birthday,
        anniversary: state.anniversary,
        gender: state.gen,
        sms_opt_out: state.sms_opt_out,
        password_confirmation: state.confirmPassword, // need to check
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Token token=--WEBRmfcyzgRpxrWEB--" + ":" + objData.apikey,
      };

      axios
        .put(API_BASE_URL + "/members", payload, {
          headers: headers,
        })
        .then(function (response) {
          if (response.status === 200) {
            setState((prevState) => ({
              ...prevState,
              successMessage: "Registration successful. Redirecting to home page..",
            }));
            console.log(response.data.apikey);
            localStorage.setItem(ACCESS_TOKEN_NAME, response.data.apikey);
            var localStoragedata = JSON.parse(localStorage.getItem("data"));
            localStoragedata.data = response.data;
            localStorage.setItem("data", JSON.stringify(localStoragedata));
            setTimeout(() => {
              props.updateTitle("Update Profile");
              //    var  phoneno= response.data.phone
              var jsonDatavar = {
                phone: state.phone,
              };
              props.history.push("/home", { state: jsonDatavar });
              //redirectToHome();
            }, 2000);
            props.showError(null);
          } else {
            props.showError("Some error ocurred");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      props.showError("Please enter valid username and password");
    }
  };
  const redirectToHome = () => {
    props.updateTitle("Home");
    props.history.push("/home");
  };
  const redirectToUpdateProfile = () => {
    // props.updateTitle('Upadte')//
    props.history.push("/UpdateProfileForm", { state: "homeData" });
  };

  const handleSubmitClick = (e) => {
    e.preventDefault();
    var objValidation = checkValidationFormAllControls(document.forms[0].elements, false, []);
    if (objValidation.valid !== false) {
      setObjError(objValidation);
      //setEmailError(objValidation['email']);
      //PasswordsetError(objValidation['password']);
      return;
    }

    sendDetailsToServer(); //
  };

  const theme = createTheme();
  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          float: "right",
          width: "100%",
          textAlign: "right",
          marginTop: "100px",
        }}
      >
        {/* <button style={{ width:'100px'}}className="buttonStyleRight" onClick={props.history.goBack}>Back</button> */}
        <Button className="Update_Button" sx={{ mt: 3, mb: 2 }}>
          <span className="backLink" onClick={props.history.goBack}>
            <a href="#">Back to Rewards</a>
          </span>
        </Button>

        <Button
          style={{ width: "100px" }}
          type="submit"
          className="Update_Button buttonStyleRight mtCustom"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={redirectToUpdateProfile}
        >
          Edit
        </Button>
      </div>

      <div className="profileTitle"> Your Profile </div>
      <Container component="main" maxWidth="">
        <CssBaseline />

        <Box component="form" noValidate sx={{ mt: 1 }}>
          <Grid container className="profileCustom" spacing={2}>
            <Grid item xs={12} md={12}>
              <div className="form-group text-left">
                <p>
                  <span>Name:</span>{" "}
                  <span>
                    {state.fname} {state.lname}
                  </span>
                </p>
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <div className="form-group text-left">
                <p>
                  <span>Emal:</span> <span>{state.email}</span>
                </p>
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <div className="form-group text-left">
                <p>
                  <span>Password:</span> <span>******** </span>
                </p>
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <div className="form-group text-left">
                <p>
                  <span>Phone Number:</span> <span>{state.phone}</span>
                </p>
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <div className="form-group text-left">
                <p>
                  <span>Address:</span> <span>{state.address1}</span>
                </p>
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <div className="form-group text-left">
                <p>
                  <span>Birthday: </span>
                  <span>{state.birthday}</span>
                </p>
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <div className="form-group text-left">
                <p>
                  <span>Gender:</span> <span>{state.gen}</span>
                </p>
              </div>
            </Grid>

            {/* <Grid item xs={12} md={4}>

                            <div className="form-group text-left">

                                <TextField margin="none"
                                    required
                                    fullWidth
                                    id="lname"
                                    name="last_name"
                                    autoComplete="lname"
                                    autoFocus
                                    label="Enter Last Name"
                                    value={state.lname}
                                    onChange={handleChange}
                                />

                            </div>
                            <span style={{
                                fontWeight: 'bold',
                                color: 'red',
                            }}>{objError.last_name}</span>
                        </Grid> */}

            {/* <Grid item xs={12} md={4}>

                            <div className="form-group text-left">

                                <TextField margin="none"
                                    required
                                    fullWidth
                                    id="email"
                                    type="email"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    label="Enter email"
                                    value={state.email}
                                    onChange={handleChange}
                                />

                            </div>
                            {objError.email ?
                                <span style={{
                                    fontWeight: 'bold',
                                    color: 'red',
                                }}>{objError.email}</span> : ''}

                        </Grid> */}

            {/* <Grid item xs={12} md={4}>
                            <div className="form-group text-left">
                                <TextField margin="none"
                                    required
                                    fullWidth
                                    inputProps={{
                                        maxLength: 10,
                                        minLength: 10
                                    }}
                                    id="phone"
                                    name="phone"
                                    autoComplete="phone"
                                    autoFocus
                                    type="tel"
                                    label="Enter phone no"
                                    value={state.phone}
                                    onChange={handleChange}
                                />
                                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                            </div>
                            {objError.phone ?
                                <span style={{
                                    fontWeight: 'bold',
                                    color: 'red',
                                }}>{objError.phone}</span> : ''}

                        </Grid> */}
          </Grid>

          <div className="alert alert-success mt-2" style={{ display: state.successMessage ? "block" : "none" }} role="alert">
            {state.successMessage}
          </div>
          <div className="mt-2">
            {/* <span>Already have an account? </span>
                <span className="loginText" onClick={() => redirectToLogin()}>Login here</span>  */}
          </div>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default withRouter(ProfilePage);
