// export const API_BASE_URL = 'https://api-staging.cinch.io/api/v1';//process.env.REACT_APP_SERVER_URL;
// export const ADMIN_API_BASE_URL = 'https://api-staging.cinch.io/admin/v1/';//process.env.REACT_APP_SERVER_URL;

// https://oilchangers.com/rewards/api/

// https://oilchangers.com/rewards/api/api/v1/member/forgot_password
// https://oilchangers.com/rewards/api/api/v1/member
/////////////////////// live setting ///////////////////////
export const API_BASE_URL = 'https://api.cinch.io/api/v1';//process.env.REACT_APP_SERVER_URL;
export const ADMIN_API_BASE_URL = 'https://api.cinch.io/admin/v1/';//process.env.REACT_APP_SERVER_URL;
export const WRAP_API_BASE_URL = 'https://rewards.oilchangers.com/api/api/v1';
export const DEVICE_KEY = 'aa869458-9eda-4a84-97d5-7c5c94ec27bd'
export const DEVICE_TOKEN = `Token token=${DEVICE_KEY}`;
export const DEVELOPER_KEY_TOKEN = 'Token token=--WEBRmfcyzgRpxrWEB--';
export const LOCATION_KEYS = ['4pR1e9YsjH9STA6vCMh-0g', '96fb7e37-7f32-4ad5-8c75-49ed33ccc59e'];
export const VENDER_KEY = 'WhwEQ1wPmmXRzKbuonQYTg'

///////////////Live end /////////////


export const ACCESS_TOKEN_NAME = 'login_access_token';
